import React, { useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, Avatar } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import SideMenu from './SideMenu';
import MainContent from '../pages/MainContent';
import logo from "../assets/logo.png"
import { Divider, Typography } from 'antd';

import { getInitials } from '../utils/helperUtils';
const { Header, Sider, Content } = Layout;


const { Text } = Typography;
const BasicLayout = ({ onLogout }) => {
    const [collapsed, setCollapsed] = useState(true);
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const location = useLocation();

    const user = JSON.parse(localStorage.getItem("user"));

    const handleLogout = () => {
        onLogout();
    };

    // Define the header name based on the current route
    // const getHeaderName = () => {
    //     switch (location.pathname) {
    //         case '/':
    //             return 'Booking Summary'
    //         case '/forms':
    //             return 'Screenings';
    //         case '/athena':
    //             return 'Athena Connector';
    //         case '/add-clinic':
    //             return 'Add Clinic';
    //         case '/clinicians':
    //             return 'Clinicians';
    //         case '/bookings':
    //             return 'Booking ';
    //         case '/referrals':
    //             return 'Referrals';
    //         case '/clinic':
    //             return 'Clinic';
    //         case '/audit-logs':
    //             return 'Audit Logs';
    //         case '/utils':
    //             return 'Utilities';
    //         default:
    //             return 'Edit Clinic'; // Default header name for the home route
    //     }
    // };

    const getHeaderName = () => {
        switch (location.pathname.split('/')[1]) {
            case 'edit-clinic':
                return 'Edit Clinic'
            case 'forms':
                return 'Screenings';
            case 'reconcile-assessment':
                return 'Reconcile Assessment';
            case 'partner-patient-appointments':
                return 'Partner Patient Appointments';
            case 'partner-patients':
                return 'Partner Patients'
            case 'partner-patient-summary':
                return 'Partner Patient Summary'
            case 'add-clinic':
                return 'Add Clinic';
            case 'clinicians':
                return 'Clinicians';
            case 'bookings':
                return 'Booking ';
            case 'referrals':
                return 'Referrals';
            case 'clinic':
                return 'Clinic';
            case 'faxes':
                return 'Faxes';
            case 'intake':
                return 'Intake';
            case 'MonthlyFaxReview':
                return 'Monthly FaxReview';
            case 'utils':
                return 'Utilities';
            case 'onboard-clinicians':
                return 'Clinician Onboarding'
            case 'dashboard':
                return 'Booking Summary'
            case 'aws-error-logs':
                return 'AWS Error Logs';
            case 'insurance-case-manager':
                return 'Insurance Case Manager';
            case 'participation-matrix':
                return 'Participation Matrix';
            case 'insurance-mapping':
                return 'Mapped Insurance List';
            default:
                return 'Performance'; // Default header name for the home route
        }
    };

    const userInitials = user ? getInitials(user.name) : '?';

    const isMobileView = window.innerWidth <= 768;
    return (
        <Layout style={{ maxHeight: '100vh', overflow: 'hidden' }}>
            <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: "#495A58" }}>
                <div className="demo-logo-vertical" />
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64,
                        marginLeft: '5px',
                        color: 'white'
                    }}
                />

                <SideMenu onLogout={handleLogout} />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: "#495A58",
                    }}
                >
                    {!isMobileView ? <img src={logo} style={{ width: "100px", marginLeft: "10px" }} /> : null}

                    {/* Header Name */}
                    {!isMobileView ? <span style={{ color: 'white', marginLeft: '20px', fontSize: '20px', marginBottom: '20px' }}>
                        | {getHeaderName()}
                    </span> : <span style={{ color: 'white', marginLeft: '20px', fontSize: '20px', marginBottom: '20px' }}>
                        {getHeaderName()}
                    </span>}


                    {/* Logout Button */}
                    <div style={{ float: 'right', marginRight: '20px', marginLeft: '20px', marginBottom: '20px' }}>
                        {!isMobileView ? <span style={{ color: 'white', marginRight: '20px', marginLeft: '20px', fontSize: '18px', marginBottom: '20px' }}>
                            {user.name}
                        </span> : null}
                        <Avatar
                            style={{
                                backgroundColor: '#329046',
                                verticalAlign: 'middle',
                            }}
                            size="large"
                        >
                            {user ? userInitials : '?'}
                        </Avatar>
                    </div>

                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        minHeight: 280,
                        background: colorBgContainer,
                        maxHeight: 'calc(100vh - 112px)',
                        overflowY: 'auto'
                    }}
                >
                    <MainContent />
                </Content>
            </Layout>
        </Layout>
    );
};
export default BasicLayout